"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const ajax_1 = require("rxjs/ajax");
const downloadPath = require('!!file-loader!./assets/download.bin').default;
const blankPath = require('!!file-loader!./assets/blank.bin').default;
const makeData = function (size, callback) {
    var randomValue = new Uint32Array(262144);
    function getRandom() {
        var n = randomValue.length;
        for (var i = 0; i < n; i++) {
            randomValue[i] = Math.random() * 4294967296;
        }
        return randomValue;
    }
    var randomData = [];
    var genData = function (dataSize) {
        var dataSize = dataSize;
        for (var i = 0; i < dataSize; i++) {
            randomData[i] = getRandom();
        }
        return randomData;
    };
    return new Blob(genData(size), { type: "application/octet-stream" });
};
const arrowSvg = require('!!svg-inline-loader!./assets/arrow.svg');
class AjaxUpdate {
}
class SpeedtestCtrl {
    constructor($scope, $timeout, $sce, $cookies, ConfigService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$sce = $sce;
        this.$cookies = $cookies;
        this.ConfigService = ConfigService;
        this.pingInterval = 300;
        this.pingTimeout = 1500;
        this.downloadTimeout = 10000;
        this.downloadBuffer = 400;
        this.uploadTimeout = 10000;
        this.uploadBuffer = 400;
        this.arrowSvg = arrowSvg;
        this.state = 'stop';
        this.stop$ = new rxjs_1.Subject();
        this.ajaxWithUpdates = (request) => {
            return new rxjs_1.Observable(subscriber => {
                const progressSubscriber = new rxjs_1.Subscriber(progressEvent => subscriber.next(progressEvent), error => subscriber.error(error), 
                // Forward next and error but not complete
                // When progress is complete, we send the response *then* complete.
                () => {
                });
                if (request.method == 'get') {
                    request['createXHR'] = () => {
                        const xhr = new XMLHttpRequest();
                        xhr.onprogress = (e) => {
                            progressSubscriber.next(e);
                        };
                        return xhr;
                    };
                }
                const ajax$ = (0, ajax_1.ajax)(Object.assign(Object.assign({}, request), { progressSubscriber }));
                const subscription = ajax$
                    .pipe((0, operators_1.map)(ajaxResponse => ajaxResponse))
                    .subscribe(subscriber);
                return () => subscription.unsubscribe();
            });
        };
    }
    $onInit() {
        // console.log(this)
    }
    download() {
        if (this.result)
            this.result.download = undefined;
        const start = new Date().valueOf();
        return this.ajaxWithUpdates({
            url: downloadPath + `?r=${Math.random()}`,
            method: 'get',
            timeout: this.downloadTimeout,
            withCredentials: false,
            responseType: 'blob',
        }).pipe((0, operators_1.tap)((i) => {
            // console.log(i)
        }), (0, operators_1.filter)((i) => i instanceof ProgressEvent), (0, operators_1.bufferTime)(this.downloadBuffer), (0, operators_1.filter)((i) => i.length > 0), (0, operators_1.tap)((i) => {
            var _a, _b, _c, _d;
            const s = (new Date().valueOf() - start) / 1000;
            const bps = (i[i.length - 1].loaded / s);
            let loadedPerBuffer = ((_b = (_a = this.result) === null || _a === void 0 ? void 0 : _a.downloadSpeed) === null || _b === void 0 ? void 0 : _b.maxDownload) || 0;
            if (i.length == 1) {
                if (i[0].total == i[0].loaded) {
                    loadedPerBuffer = i[0].loaded;
                }
            }
            else {
                loadedPerBuffer = i[i.length - 1].loaded - i[0].loaded;
            }
            const _maxDownload = Math.max(((_d = (_c = this.result) === null || _c === void 0 ? void 0 : _c.downloadSpeed) === null || _d === void 0 ? void 0 : _d.maxDownload) || 0, loadedPerBuffer);
            const maxDownload = 110 * _maxDownload / 100;
            this.$timeout(() => {
                this.result = Object.assign(Object.assign({}, this.result), { downloadSpeed: {
                        loadedPerBuffer: loadedPerBuffer,
                        maxDownload: _maxDownload,
                        bps: bps,
                        MBps: bps * 0.000001,
                        KBps: bps * 0.001,
                        Mbps: bps * 0.000008,
                        Kbps: bps * 0.008,
                        downloadPercent: maxDownload ? (loadedPerBuffer * 100 / maxDownload).toFixed(0) : 0,
                    } });
            });
        }), (0, operators_1.catchError)((e) => {
            console.error(e);
            return (0, rxjs_1.of)(rxjs_1.EMPTY);
        }));
    }
    upload() {
        if (this.result)
            this.result.upload = undefined;
        const start = new Date().valueOf();
        const form = new FormData();
        form.append('blob', makeData(30));
        form.append('csrfmiddlewaretoken', this.$cookies.get('csrftoken'));
        return this.ajaxWithUpdates({
            url: `?r=${Math.random()}`,
            method: 'post',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: form,
            timeout: this.uploadTimeout,
            withCredentials: true,
        }).pipe((0, operators_1.tap)((i) => {
            // console.log(i)
        }), (0, operators_1.filter)((i) => i instanceof ProgressEvent), (0, operators_1.bufferTime)(this.uploadBuffer), (0, operators_1.filter)((i) => i.length > 0), (0, operators_1.tap)((i) => {
            var _a, _b, _c, _d;
            const s = (new Date().valueOf() - start) / 1000;
            // const loaded = i.reduce((partialSum, a) => partialSum + a.loaded, 0)
            const bps = i[i.length - 1].loaded / s;
            let loadedPerBuffer = ((_b = (_a = this.result) === null || _a === void 0 ? void 0 : _a.uploadSpeed) === null || _b === void 0 ? void 0 : _b.maxUpload) || 0;
            if (i.length == 1) {
                if (i[0].total == i[0].loaded) {
                    loadedPerBuffer = i[0].loaded;
                }
            }
            else {
                loadedPerBuffer = i[i.length - 1].loaded - i[0].loaded;
            }
            const _maxUpload = Math.max(((_d = (_c = this.result) === null || _c === void 0 ? void 0 : _c.uploadSpeed) === null || _d === void 0 ? void 0 : _d.maxUpload) || 0, loadedPerBuffer);
            const maxUpload = 110 * _maxUpload / 100;
            // console.log(
            //     {
            //         i: i,
            //         loadedPerBuffer: loadedPerBuffer,
            //         maxUpload: _maxUpload,
            //         bps: bps,
            //         // MBps: bps * 0.000001,
            //         // KBps: bps * 0.001,
            //         // Mbps: bps * 0.000008,
            //         // Kbps: bps * 0.008,
            //         uploadPercent: maxUpload ? (loadedPerBuffer * 100 / maxUpload).toFixed(0) : 0,
            //     }
            // )
            this.$timeout(() => {
                this.result = Object.assign(Object.assign({}, this.result), { uploadSpeed: {
                        loadedPerBuffer: loadedPerBuffer,
                        maxUpload: _maxUpload,
                        bps: bps,
                        MBps: bps * 0.000001,
                        KBps: bps * 0.001,
                        Mbps: bps * 0.000008,
                        Kbps: bps * 0.008,
                        uploadPercent: maxUpload ? (loadedPerBuffer * 100 / maxUpload).toFixed(0) : 0,
                    } });
            });
        }), (0, operators_1.catchError)((e) => {
            console.error(e);
            return (0, rxjs_1.of)(rxjs_1.EMPTY);
        }));
    }
    ping() {
        if (this.result)
            this.result.ping = undefined;
        return (0, rxjs_1.interval)(this.pingInterval).pipe((0, operators_1.startWith)(-1), (0, operators_1.take)(Math.ceil(7 * 1000 / this.pingInterval)), (0, operators_1.concatMap)(() => {
            const start = new Date().valueOf();
            const url = blankPath + `?r=${Math.random()}`;
            return this.ajaxWithUpdates({
                url: url,
                method: 'get',
                timeout: this.pingTimeout,
                withCredentials: false,
                // responseType: 'blob',
            }).pipe((0, operators_1.filter)((i) => {
                if (i instanceof ajax_1.AjaxResponse) {
                    if (i.status === 200 && i.xhr.readyState === 4) {
                        return true;
                    }
                }
                return false;
            }), (0, operators_1.map)((i) => {
                const entries = performance.getEntriesByType('resource');
                if (entries.length) {
                    const lastEntry = entries[entries.length - 1];
                    if (lastEntry.initiatorType == 'xmlhttprequest') {
                        return {
                            ping: lastEntry.duration
                        };
                    }
                }
                return {
                    ping: new Date().valueOf() - start
                };
            }));
        }), (0, operators_1.pairwise)(), (0, operators_1.tap)((i) => {
            var _a, _b;
            let jitter = 0;
            if (i[0]) {
                jitter = Math.abs(i[1].ping - i[0].ping);
            }
            const _maxPing = Math.max(((_a = this.result) === null || _a === void 0 ? void 0 : _a.maxPing) || 0, i[1].ping);
            const maxPing = 110 * _maxPing / 100;
            const _maxJitter = Math.max(((_b = this.result) === null || _b === void 0 ? void 0 : _b.maxJitter) || 0, jitter);
            const maxJitter = 110 * _maxJitter / 100;
            this.$timeout(() => {
                this.result = Object.assign(Object.assign({}, this.result), { ping: i[1].ping, maxPing: _maxPing, maxJitter: _maxJitter, pingPercent: (i[1].ping * 100 / maxPing).toFixed(0), jitterPercent: (jitter * 100 / maxJitter).toFixed(0), jitter: jitter });
            });
        }), (0, operators_1.toArray)(), (0, operators_1.tap)((items) => {
            this.result.avgPing = (items.reduce((partialSum, a) => partialSum + a[1].ping, 0) / items.length).toFixed(2);
        }), (0, operators_1.map)((items) => {
            return items.length;
        }), (0, operators_1.catchError)((e) => {
            return (0, rxjs_1.of)(rxjs_1.EMPTY);
        }));
    }
    start() {
        this.result = {};
        this.state = 'play';
        (0, rxjs_1.concat)(this.ping(), this.download(), this.upload().pipe((0, operators_1.tap)(() => {
            this.result.fullTest = true;
        }))).pipe((0, operators_1.takeUntil)(this.stop$), (0, operators_1.finalize)(() => {
            this.$timeout(() => {
                this.state = 'stop';
            });
        })).subscribe();
    }
    stop() {
        this.stop$.next();
        this.result = {};
    }
}
SpeedtestCtrl.$inject = ['$scope', '$timeout', '$sce', '$cookies', 'ConfigService'];
const appModule = ng.module('app');
appModule.component('gameSpeedtest', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: SpeedtestCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<",
        clientIp: "<",
        provider: "<",
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('speedtest/');
    }]);
